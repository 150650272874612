import * as React from 'react';
import { useAuth } from 'contexts/authUser/store';
import { authUserSelectors } from 'contexts/authUser';
import Heading from 'components/text/Heading';
import { useTranslation } from 'next-i18next';
import Paragraph from 'components/text/Paragraph';
import { routes } from 'utils/routes';
import View from 'components/View';
import Button from 'components/Button';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';

const HomeSignUp: React.FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth(state => ({
    isLoggedIn: authUserSelectors.isLoggedIn(state)
  }));

  const logAnalyticEvent = () => {
    analytics.logEvent(ANALYTIC_EVENTS.HOME_SIGN_UP);
  };

  // hide this section if user is logged in
  if (isLoggedIn) return null;

  return (
    <div className="HomeSignUp bg-neutral-900 text-center py-[224px] md:py-[224px] border-t-[2px] border-t-white">
      <View className="sm:!max-w-[640px]">
        <Heading variant="h2" className="mb-4 text-center text-white">
          {t('home.signUp.title')}
        </Heading>
        <Paragraph className="text-center mb-8 text-white">
          {t('home.signUp.description')}
        </Paragraph>
        <Button variant="primary-badge" href={routes.auth.register()} onClick={logAnalyticEvent}>
          {t('home.signUp.signUp')}
        </Button>
      </View>
    </div>
  );
};

export default HomeSignUp;
